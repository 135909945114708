// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useRef } from "react";
import useScrollBlock from "../../hooks/useScrollBlock";
import {
  brushSizeAtom,
  canvasHeightAtom,
  canvasImageUrlAtom,
  canvasWidthAtom,
  clearCanvasAtom,
  hasDrawnAtom,
  isDrawingAtom,
  isProcessingAtom,
  payloadHeightAtom,
  payloadWidthAtom,
  selectedBrushElementAtom,
} from "../../utils/initState";

export default function Canvas() {
  const [blockScroll, allowScroll] = useScrollBlock();

  const canvasRef = useRef(null);
  const contextRef = useRef(null);

  const [isDrawing, setIsDrawing] = useAtom(isDrawingAtom);
  const [clearCanvas, setClearCanvas] = useAtom(clearCanvasAtom);

  const setCanvasImageUrl = useSetAtom(canvasImageUrlAtom);

  const setHasDrawn = useSetAtom(hasDrawnAtom);

  const canvasHeight = useAtomValue(canvasHeightAtom);
  const canvasWidth = useAtomValue(canvasWidthAtom);

  const payloadWidth = useAtomValue(payloadWidthAtom);
  const payloadHeight = useAtomValue(payloadHeightAtom);

  const selectedBrushElement = useAtomValue(selectedBrushElementAtom);

  const isProcessing = useAtomValue(isProcessingAtom);

  const brushSize = useAtomValue(brushSizeAtom);

  function startDrawing({ nativeEvent }) {
    const { offsetX, offsetY } = nativeEvent;

    contextRef.current.moveTo(offsetX, offsetY);
    // displayContextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
    setHasDrawn(true);
  }

  function startTouch(e) {
    // console.log(e.touches);

    e.preventDefault();

    blockScroll();

    const { pageX, pageY } = e.touches[0];

    // console.log(pageX, pageY);

    contextRef.current.moveTo(pageX, pageY);
    // displayContextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
    setHasDrawn(true);
  }

  function finishDrawing() {
    contextRef.current.closePath();
    // displayContextRef.current.closePath();
    allowScroll();

    setIsDrawing(false);
  }

  function draw({ nativeEvent }) {
    if (!isDrawing) return;

    const { clientX, clientY } = nativeEvent;

    contextRef.current.beginPath();
    // displayContextRef.current.beginPath();

    const canvasRect = canvasRef.current.getBoundingClientRect();
    const canvasX = canvasRect.left;
    const canvasY = canvasRect.top;

    contextRef.current.arc(
      clientX - canvasX,
      clientY - canvasY,
      brushSize,
      0,
      2 * Math.PI,
    );

    // const displayCanvasRect = displayCanvasRef.current.getBoundingClientRect();
    // const displayCanvasX = displayCanvasRect.left;
    // const displayCanvasY = displayCanvasRect.top;

    // displayContextRef.current.arc(
    //   clientX - displayCanvasX,
    //   clientY - displayCanvasY,
    //   brushSize,
    //   0,
    //   2 * Math.PI,
    // );

    contextRef.current.fillStyle = "#FFFFFF";

    // displayContextRef.current.fillStyle = "#FFFFFF";

    if (selectedBrushElement === "erase") {
      contextRef.current.fillStyle = "#000000";
      contextRef.current.globalCompositeOperation = "destination-out";

      // displayContextRef.current.fillStyle = "#000000";
      // displayContextRef.current.globalCompositeOperation = "destination-out";
    } else {
      contextRef.current.globalCompositeOperation = "source-over";
      // displayContextRef.current.globalAlpha = 0.25;
      // displayContextRef.current.globalCompositeOperation = "xor";
    }

    // contextRef.current.globalAlpha = 0.1;
    // contextRef.current.globalAlpha = 0.15;

    contextRef.current.fill();

    // displayContextRef.current.fill();

    // displayContextRef.current.globalAlpha = 1;
  }

  function touchDraw(e) {
    // e.preventDefault();

    if (!isDrawing) return;

    const { clientX, clientY } = e.touches[0];

    contextRef.current.beginPath();

    const canvasRect = canvasRef.current.getBoundingClientRect();
    const canvasX = canvasRect.left;
    const canvasY = canvasRect.top;

    contextRef.current.arc(
      clientX - canvasX,
      clientY - canvasY,
      brushSize,
      0,
      2 * Math.PI,
    );

    contextRef.current.fillStyle = "#FFFFFF";

    if (selectedBrushElement === "erase") {
      contextRef.current.fillStyle = "#000000";
      contextRef.current.globalCompositeOperation = "destination-out";
    } else {
      contextRef.current.globalCompositeOperation = "source-over";
    }

    contextRef.current.fill();
  }

  useEffect(() => {
    function prepareCanvas() {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      context.globalAlpha = 0;

      canvas.fillStyle = "#000000";

      context.fillRect(0, 0, canvas.width, canvas.height);
      context.globalAlpha = 1;

      contextRef.current = context;

      setHasDrawn(false);
    }

    prepareCanvas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHasDrawn, canvasHeight, canvasWidth]);

  useEffect(() => {
    if (!isDrawing && canvasRef.current && canvasWidth > 0) {
      const tempCanvas = document.createElement("canvas");

      tempCanvas.width = payloadWidth;
      tempCanvas.height = payloadHeight;

      const tempContext = tempCanvas.getContext("2d");

      tempContext.drawImage(
        canvasRef.current, // Canvas img source
        0, //sx
        0, // sy
        canvasWidth, //sw
        canvasHeight, //sh
        0, // dx
        0, // dy
        tempCanvas.width, // dw
        tempCanvas.height, //dh
      );

      const url = tempCanvas.toDataURL(); // Convert to base64 image

      setCanvasImageUrl(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawing, payloadHeight, payloadWidth, setCanvasImageUrl]);

  useEffect(() => {
    function clear() {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      context.globalAlpha = 0;

      canvas.fillStyle = "#000000";

      context.fillRect(0, 0, canvas.width, canvas.height);
      context.globalAlpha = 1;

      contextRef.current = context;

      setClearCanvas(false);
      setHasDrawn(false);
    }

    if (clearCanvas) {
      clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCanvas]);

  return (
    <canvas
      ref={canvasRef}
      className="canvas"
      hidden={isProcessing}
      onMouseMove={draw}
      onMouseDown={startDrawing}
      onMouseUp={finishDrawing}
      onMouseLeave={finishDrawing}
      onTouchStart={startTouch}
      onTouchEnd={finishDrawing}
      onTouchMove={touchDraw}
    />
  );
}
