// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useRef } from "react";
import toast from "react-hot-toast";
import {} from "../../utils/helpers";
import {
  generatedImageUrlAtom,
  gotGeneratedImageAtom,
  hasUpscaledImageAtom,
  payloadHeightAtom,
  payloadWidthAtom,
  scaleAtom,
  tabSelectedAtom,
  uploadedImageAtom,
  uploadedImageHeightAtom,
  uploadedImageWidthAtom,
} from "../../utils/initState";
import { toastError } from "../../utils/toastStyles";
import Button from "../Button";
import { UploadImageIcon } from "../Icons";

export default function UploadImage() {
  const inputRef = useRef(null);

  const [scale, setScale] = useAtom(scaleAtom);

  const setPayloadWidth = useSetAtom(payloadWidthAtom);
  const setPayloadHeight = useSetAtom(payloadHeightAtom);
  const setUploadedImage = useSetAtom(uploadedImageAtom);
  const setHasUpscaledImage = useSetAtom(hasUpscaledImageAtom);
  const setGotGeneratedImage = useSetAtom(gotGeneratedImageAtom);
  const setGeneratedImageUrl = useSetAtom(generatedImageUrlAtom);
  const setUploadedImageWidth = useSetAtom(uploadedImageWidthAtom);
  const setUploadedImageHeight = useSetAtom(uploadedImageHeightAtom);

  const tabSelected = useAtomValue(tabSelectedAtom);

  function handleClick() {
    inputRef.current?.click();
  }

  function uploadImage(e) {
    const file = e.target.files[0];

    const maxSize = tabSelected === 4 ? 1536 : 5600; // Max image size in bytes

    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (e) => {
        const img = new Image();

        img.src = e.target.result;

        img.onload = () => {
          const { width, height } = img;

          if (height > maxSize || width > maxSize) {
            setUploadedImage("");

            toast.error(`Height/width must be smaller than ${maxSize}px!`, {
              duration: 5000,
              style: toastError,
            });
          } else {
            setGeneratedImageUrl("");

            let newWidth;
            let newHeight;

            if (width > maxSize / 2 || height > maxSize / 2) {
              newWidth = Math.floor(((width / 2) * scale) / 8) * 8;
              newHeight = Math.floor(((height / 2) * scale) / 8) * 8;
            } else {
              newWidth = Math.floor((width * scale) / 8) * 8;
              newHeight = Math.floor((height * scale) / 8) * 8;
            }

            // Draw uploaded image to canvas
            const tempCanvas = document.createElement("canvas");

            tempCanvas.width = newWidth;
            tempCanvas.height = newHeight;

            const tempContext = tempCanvas.getContext("2d");

            tempContext.drawImage(
              img, // Canvas img source
              0, //sx
              0, // sy
              width, //sw
              height, //sh
              0, // dx
              0, // dy
              newWidth, // dw
              newHeight, //dh
            );

            const url = tempCanvas.toDataURL(file.type); // Convert to base64 image

            setUploadedImage(url);
            setUploadedImageWidth(newWidth);
            setUploadedImageHeight(newHeight);
            setHasUpscaledImage(false);
            setPayloadWidth(newWidth);
            setPayloadHeight(newHeight);
            setGotGeneratedImage(false);
            setScale(1);
          }
        };
      };
    }
  }

  return (
    <>
      <Button
        tabIndex={0}
        label="Upload"
        onClick={handleClick}
        styles="btn-block btn-dark"
        icon={<UploadImageIcon size={20} />}
      />
      <input
        hidden
        type="file"
        ref={inputRef}
        alt="uploaded image"
        onChange={uploadImage}
        accept="image/png, image/jpeg, image/webp, image/bmp, image/gif"
      />
    </>
  );
}
