// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtomValue } from "jotai";
import { Toaster } from "react-hot-toast";
import Header from "../components/Header";
import ImageLoader from "../components/ImageLoader";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import TabView from "../components/TabView";
import { isLoadingAtom } from "../utils/initState";

export default function HomePage() {
  const isLoading = useAtomValue(isLoadingAtom);

  if (isLoading) {
    return <Loader label="Loading, please wait..." />;
  }

  return (
    <>
      <Layout
        headerContent={<Header />}
        middleContent={<ImageLoader />}
        footerContent={<TabView />}
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
}
