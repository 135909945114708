// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
export function GoogleIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
    </svg>
  );
}

export function DownloadIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z"
      />
      <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
    </svg>
  );
}

export function SignOutIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
      />
      <path
        fillRule="evenodd"
        d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
      />
    </svg>
  );
}

export function ImageIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
      <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
    </svg>
  );
}

export function ClearIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
    </svg>
  );
}

export function RightArrow({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
      />
      <path
        fillRule="evenodd"
        d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
      />
    </svg>
  );
}

export function Square({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
    </svg>
  );
}

export function Portrait({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
      <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
    </svg>
  );
}

export function Landscape({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4zm-1 8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8z" />
      <path d="M14 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0z" />
    </svg>
  );
}

export function UltraWide({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M1 4.5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-6zm-1 6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v6z" />
      <path d="M14 7.5a1 1 0 1 0-2 0 1 1 0 0 0 2 0z" />
    </svg>
  );
}

export function CloseIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
    </svg>
  );
}

export function ProductIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M12.17 9.53c2.307-2.592 3.278-4.684 3.641-6.218.21-.887.214-1.58.16-2.065a3.578 3.578 0 0 0-.108-.563 2.22 2.22 0 0 0-.078-.23V.453c-.073-.164-.168-.234-.352-.295a2.35 2.35 0 0 0-.16-.045 3.797 3.797 0 0 0-.57-.093c-.49-.044-1.19-.03-2.08.188-1.536.374-3.618 1.343-6.161 3.604l-2.4.238h-.006a2.552 2.552 0 0 0-1.524.734L.15 7.17a.512.512 0 0 0 .433.868l1.896-.271c.28-.04.592.013.955.132.232.076.437.16.655.248l.203.083c.196.816.66 1.58 1.275 2.195.613.614 1.376 1.08 2.191 1.277l.082.202c.089.218.173.424.249.657.118.363.172.676.132.956l-.271 1.9a.512.512 0 0 0 .867.433l2.382-2.386c.41-.41.668-.949.732-1.526l.24-2.408Zm.11-3.699c-.797.8-1.93.961-2.528.362-.598-.6-.436-1.733.361-2.532.798-.799 1.93-.96 2.528-.361.599.599.437 1.732-.36 2.531Z" />
      <path d="M5.205 10.787a7.632 7.632 0 0 0 1.804 1.352c-1.118 1.007-4.929 2.028-5.054 1.903-.126-.127.737-4.189 1.839-5.18.346.69.837 1.35 1.411 1.925Z" />
    </svg>
  );
}

export function UploadImageIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
      <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
    </svg>
  );
}

export function ToggleOffIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z" />
    </svg>
  );
}

export function ToggleOnIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
    </svg>
  );
}

export function DrawIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.067 6.067 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.118 8.118 0 0 1-3.078.132 3.659 3.659 0 0 1-.562-.135 1.382 1.382 0 0 1-.466-.247.714.714 0 0 1-.204-.288.622.622 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896.126.007.243.025.348.048.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04zM4.705 11.912a1.23 1.23 0 0 0-.419-.1c-.246-.013-.573.05-.879.479-.197.275-.355.532-.5.777l-.105.177c-.106.181-.213.362-.32.528a3.39 3.39 0 0 1-.76.861c.69.112 1.736.111 2.657-.12.559-.139.843-.569.993-1.06a3.122 3.122 0 0 0 .126-.75l-.793-.792zm1.44.026c.12-.04.277-.1.458-.183a5.068 5.068 0 0 0 1.535-1.1c1.9-1.996 4.412-5.57 6.052-8.631-2.59 1.927-5.566 4.66-7.302 6.792-.442.543-.795 1.243-1.042 1.826-.121.288-.214.54-.275.72v.001l.575.575zm-4.973 3.04.007-.005a.031.031 0 0 1-.007.004zm3.582-3.043.002.001h-.002z" />
    </svg>
  );
}

export function EraseIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414l-3.879-3.879zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z" />
    </svg>
  );
}

export function ShareIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
    </svg>
  );
}

export function CircleIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
    </svg>
  );
}

export function StarsIcon({ size }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
      <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
    </svg>
  );
}

export function LogoIcon({ size }) {
  return (
    <svg width={size} height={size} className="logo-svg" viewBox="0 0 300 300">
      <g transform="translate(0,300) scale(0.1,-0.1)">
        <path
          d="M1269 2765 c-333 -63 -614 -241 -818 -518 -41 -56 -47 -87 -18 -87 8
0 41 35 73 78 167 221 411 387 674 456 107 28 233 46 328 46 161 0 395 -56
544 -131 159 -79 335 -222 428 -348 31 -42 52 -60 68 -61 35 0 27 23 -36 102
-174 218 -407 368 -692 445 -83 22 -120 26 -285 29 -136 2 -211 -1 -266 -11z"
        />
        <path
          d="M1864 2256 c-3 -14 1 -37 10 -55 l17 -31 -436 0 c-438 0 -475 -3
-475 -35 0 -13 -60 -15 -434 -15 -258 0 -445 -4 -460 -10 -54 -20 -39 -40 212
-270 l113 -104 227 -48 c125 -26 277 -59 338 -73 l112 -26 30 -57 c52 -98 52
-97 -20 -120 -35 -12 -171 -55 -303 -97 -132 -43 -250 -83 -262 -91 -17 -11
-23 -24 -23 -49 0 -35 28 -75 52 -75 7 0 134 29 282 65 148 36 271 65 273 65
2 0 1 -4 -2 -10 -3 -5 -13 -10 -22 -10 -22 0 -355 -188 -375 -212 -15 -17 -18
-40 -18 -143 0 -68 3 -130 6 -139 5 -14 39 -16 273 -16 l268 0 22 24 c41 43
137 79 226 84 105 6 189 -16 268 -68 l60 -40 251 2 251 3 3 146 3 147 -110 63
c-109 63 -128 79 -93 79 37 0 357 98 365 112 7 9 -2 57 -26 143 -20 71 -35
130 -34 132 2 1 18 5 37 8 64 11 67 22 36 132 -15 54 -34 110 -41 126 -13 25
-12 27 12 33 13 4 35 2 48 -4 18 -9 32 -6 71 15 27 14 61 34 77 45 l27 19 0
129 c0 107 -3 129 -16 134 -9 3 -155 6 -326 6 l-310 0 -60 55 c-68 63 -113 74
-124 31z m238 -193 c105 -104 206 -213 226 -243 20 -30 39 -57 42 -60 10 -9
59 -195 52 -198 -33 -13 -33 -19 2 -143 20 -68 36 -131 36 -140 0 -12 -40 -27
-171 -63 -95 -26 -178 -50 -185 -53 -8 -3 -16 6 -20 24 -3 15 -19 74 -35 131
-31 108 -34 126 -21 118 4 -2 46 6 92 19 47 14 88 25 92 25 4 0 12 -19 18 -42
6 -24 22 -76 35 -115 15 -45 20 -75 14 -79 -16 -9 -19 -2 -46 103 -32 128 -32
128 -43 117 -6 -6 2 -47 21 -107 17 -54 29 -106 27 -115 -2 -9 -14 22 -27 71
-12 48 -26 85 -30 83 -4 -3 -7 8 -6 22 0 21 -5 28 -24 30 -21 3 -23 1 -17 -20
23 -83 46 -194 42 -205 -3 -7 -12 -13 -19 -13 -10 0 -18 25 -26 88 -6 49 -11
100 -11 115 0 20 -5 27 -19 27 -11 0 -22 -3 -24 -7 -2 -5 6 -55 19 -113 33
-142 25 -151 -11 -12 -16 62 -32 112 -37 112 -4 0 -8 -3 -8 -7 0 -9 62 -226
66 -232 2 -2 34 6 71 17 37 10 112 32 166 48 l99 28 -6 30 c-3 17 -16 69 -29
116 -24 85 -38 102 -70 83 -12 -7 -8 -25 21 -101 25 -64 38 -88 45 -80 6 9 7
7 3 -5 -3 -10 -6 -23 -6 -28 0 -5 -4 -9 -8 -9 -5 0 -26 49 -47 110 -20 60 -41
108 -45 105 -4 -3 10 -53 31 -112 45 -123 43 -113 27 -113 -8 0 -26 43 -43
101 -33 111 -40 127 -52 116 -4 -4 10 -56 30 -114 24 -70 33 -111 27 -117 -14
-14 -16 -10 -45 99 -14 55 -30 108 -35 117 -8 14 1 19 65 34 44 10 75 23 75
30 0 13 -66 -3 -270 -63 -128 -37 -140 -39 -140 -17 0 11 -11 55 -24 98 -24
78 -25 82 -11 175 8 53 15 156 15 229 0 116 -3 143 -25 208 -27 82 -28 84 -19
84 4 0 92 -84 196 -187z m-188 36 c10 -52 7 -237 -6 -341 -11 -88 -14 -98 -32
-98 -12 0 -45 -9 -74 -20 -29 -11 -171 -58 -317 -105 -146 -47 -271 -88 -279
-91 -9 -3 -17 8 -24 38 -18 70 -67 136 -110 147 -37 10 -164 38 -294 66 -38 8
-98 22 -135 30 -38 8 -101 21 -142 30 -73 16 -74 16 -194 128 -66 62 -141 131
-166 154 l-46 43 463 0 c435 0 463 1 461 18 -1 9 -1 20 0 25 0 4 201 7 445 7
l444 0 6 -31z m756 -73 l0 -104 -60 -36 c-51 -30 -64 -34 -84 -25 -23 11 -58
2 -148 -36 -12 -4 -18 -3 -18 5 0 7 -29 42 -65 79 -36 37 -65 69 -65 72 0 2
47 4 105 4 l105 0 0 -57 c0 -45 3 -58 15 -58 12 0 15 15 15 73 l0 72 -132 -3
-133 -2 -63 60 -63 60 296 0 295 0 0 -104z m-206 -341 c29 -106 31 -118 15
-113 -7 2 -24 46 -40 98 -33 111 -33 110 -15 110 8 0 23 -34 40 -95z m-560
-150 c32 -105 33 -109 13 -113 -9 -2 -19 18 -32 65 -10 37 -23 82 -31 100 -7
17 -10 34 -6 37 17 17 30 -3 56 -89z m-60 -26 c14 -48 26 -89 26 -92 0 -2 -26
-7 -57 -10 -32 -2 -123 -19 -203 -36 -80 -17 -208 -44 -285 -60 -77 -16 -268
-60 -425 -97 -352 -83 -346 -82 -355 -55 -9 29 -1 37 53 54 315 100 1181 381
1192 388 21 11 23 6 54 -92z m545 -73 c6 -22 8 -42 6 -45 -3 -2 -5 1 -5 8 0 7
-4 9 -10 6 -6 -3 -7 1 -4 9 3 9 1 30 -5 48 -6 18 -7 28 -2 23 5 -6 14 -28 20
-49z m-363 -157 c18 -63 31 -115 29 -117 -2 -2 -17 5 -34 17 -16 12 -45 28
-64 36 -28 12 -36 22 -46 59 -16 66 -14 106 7 112 9 3 24 9 32 14 29 15 44 -9
76 -121z m-154 33 c6 -84 32 -145 59 -137 12 4 76 -28 194 -97 l175 -103 0
-122 0 -123 -230 0 -230 0 -49 35 c-76 55 -147 75 -266 75 -74 0 -117 -5 -157
-19 -58 -20 -138 -68 -138 -82 0 -5 -110 -9 -245 -9 l-245 0 0 124 0 124 169
96 c93 53 178 96 189 96 11 0 35 17 54 39 33 39 33 39 208 76 197 40 287 60
375 80 33 7 76 14 96 14 l36 1 5 -68z m342 -84 c-12 -19 -22 -5 -27 40 -3 26
-8 63 -11 82 -3 19 5 2 19 -39 13 -41 22 -78 19 -83z"
        />
        <path
          d="M2120 1969 c0 -4 16 -33 35 -64 30 -51 65 -143 65 -174 0 -5 -19 -15
-42 -21 -124 -32 -234 -62 -241 -67 -4 -2 -5 -8 -2 -13 3 -4 71 9 153 31 152
40 171 45 235 60 47 12 50 34 3 25 -32 -6 -34 -5 -71 59 -35 59 -133 179 -135
164z"
        />
        <path
          d="M181 2037 c21 -19 43 -25 101 -30 41 -4 82 -9 90 -12 8 -3 25 -28 39
-55 13 -27 29 -50 35 -50 7 0 24 16 39 36 27 35 29 36 79 30 80 -10 359 -57
366 -61 4 -2 26 16 50 40 l44 45 158 0 157 0 27 -45 c14 -25 29 -45 32 -45 3
0 25 18 48 41 l42 41 163 8 c90 5 181 9 202 10 20 0 37 5 37 10 0 7 -155 10
-444 10 l-444 0 -32 -37 -32 -36 -92 16 c-50 9 -158 28 -241 41 -82 14 -166
27 -185 30 -35 6 -147 21 -225 31 l-40 5 26 -23z m299 -58 c0 -4 -6 -12 -14
-18 -10 -8 -16 -8 -25 2 -6 8 -8 16 -5 20 8 8 44 5 44 -4z"
        />
        <path
          d="M396 2053 c-4 -4 48 -15 116 -25 68 -9 184 -26 258 -37 157 -23 175
-19 134 35 l-26 34 -238 0 c-130 0 -240 -3 -244 -7z"
        />
        <path
          d="M2500 2100 c0 -6 28 -10 65 -10 l65 0 0 -86 c0 -54 4 -83 10 -79 6 3
10 46 10 96 l0 89 -75 0 c-43 0 -75 -4 -75 -10z"
        />
        <path
          d="M1960 1144 c0 -5 4 -14 8 -20 9 -15 221 -128 228 -122 2 3 -39 31
-93 63 -54 32 -108 65 -120 73 -13 8 -23 10 -23 6z"
        />
        <path
          d="M948 1072 c-65 -37 -118 -71 -118 -75 0 -5 59 22 130 59 119 62 151
85 118 83 -7 0 -66 -30 -130 -67z"
        />
        <path
          d="M1403 1050 c-23 -5 -76 -22 -118 -39 l-77 -31 -219 0 c-139 0 -219
-4 -219 -10 0 -6 30 -11 68 -12 155 -4 352 -19 335 -25 -21 -8 -14 -25 7 -18
17 6 19 3 15 -24 -3 -17 -7 -44 -10 -61 -7 -43 12 -37 25 8 23 89 92 144 215
177 112 29 229 11 325 -50 47 -29 65 -54 86 -117 8 -26 20 -48 25 -48 9 0 7
32 -7 98 -4 20 -2 23 17 17 17 -6 21 -4 16 8 -5 13 8 16 76 22 108 8 180 12
245 14 28 0 52 6 52 11 0 6 -76 10 -209 10 -200 0 -211 1 -262 25 -113 51
-264 69 -386 45z"
        />
        <path
          d="M2711 1871 c-15 -10 -14 -24 15 -151 23 -102 24 -335 1 -450 -117
-596 -627 -1020 -1228 -1020 -397 0 -781 199 -1010 523 -191 270 -265 597
-213 934 14 89 15 106 3 114 -21 13 -31 -4 -46 -78 -24 -121 -28 -305 -9 -432
81 -544 470 -960 1011 -1081 121 -27 365 -33 485 -11 543 97 965 521 1055
1060 23 136 16 388 -13 504 -21 86 -30 101 -51 88z"
        />
      </g>
    </svg>
  );
}
