// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtom, useAtomValue } from "jotai";
import { advancedModeAtom, negativePromptAtom } from "../../utils/initState";

export default function NegativePromptInput() {
  const [negativePrompt, setNegativePrompt] = useAtom(negativePromptAtom);

  const advancedMode = useAtomValue(advancedModeAtom);

  return (
    advancedMode && (
      <div className="prompt-input-wrapper">
        <input
          required
          type="text"
          value={negativePrompt}
          className="prompt-input"
          placeholder="Enter a negative prompt..."
          onChange={(e) => setNegativePrompt(e.target.value)}
        />
      </div>
    )
  );
}
