// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtom } from "jotai";
import { selectedSizeAtom } from "../../utils/initState";

export default function SizeElement({ size, label, icon, tabIndex }) {
  const [selectedSize, setSelectedSize] = useAtom(selectedSizeAtom);

  function handleSizeSelect() {
    setSelectedSize(size);
  }

  function handleOnEnter(e) {
    if (e.key === "Enter") {
      e.stopPropagation();
      setSelectedSize(size);
    }
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleSizeSelect}
      onKeyUp={handleOnEnter}
      className={`size-selector-section ${
        selectedSize === size && "size-selector-section-active"
      }`}
    >
      <div className="size-selector-section-icon">{icon}</div>

      {label ?? size}
    </div>
  );
}
