// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtomValue, useSetAtom } from "jotai";
import productIcon from "../../images/SubscribeIcon.webp";
import { getCheckoutUrl } from "../../stripePayment";
import { isLoadingAtom, stripeStatusAtom } from "../../utils/initState";
import Button from "../Button";
import { ProductIcon } from "../Icons";

export default function ProductCard({ label, price, actionLabel }) {
  const setIsLoading = useSetAtom(isLoadingAtom);
  const stripeStatus = useAtomValue(stripeStatusAtom);

  async function buyMembership() {
    setIsLoading(true);
    const checkoutUrl = await getCheckoutUrl(stripeStatus);

    window.location.href = checkoutUrl;
  }

  return (
    <div className="product-card-wrapper">
      <h1>Get started with ArtForge</h1>

      <div className="product-card">
        <img src={productIcon} alt="" />

        <br />

        <h1>{label}</h1>

        <br />

        <p className="price">{price}</p>

        <br />

        <h2>{actionLabel}</h2>

        <br />

        <ul>
          <li>Cancel anytime</li>
          <li>100% private</li>
          <li>SDXL based models</li>
          <li>Unlimited image generation</li>
          <li>Mobile friendly</li>
          <li>Runs in the browser</li>
        </ul>

        <Button
          tabIndex={0}
          label="Buy now"
          icon={<ProductIcon size={24} />}
          styles="btn-block btn-green"
          onClick={buyMembership}
        />

        <br />
      </div>
    </div>
  );
}
