// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import useWebsocket from "../../hooks/useWebsocket";
import {
  generatedImageUrlAtom,
  gotGeneratedImageAtom,
  isLoadingAtom,
  isProcessingAtom,
  payloadHeightAtom,
  payloadWidthAtom,
  selectedSizeAtom,
} from "../../utils/initState";
import Button from "../Button";
import DownloadButton from "../DownloadButton";
import { ImageIcon } from "../Icons";
import Loader from "../Loader";
import NegativePromptInput from "../NegativePrompt";
import PromptInput from "../PromptInput";
import SizeSelector from "../SizeSelector";
import StyleSelector from "../StyleSelector";

export default function Text2Image() {
  const { getImage } = useWebsocket();

  const size = useAtomValue(selectedSizeAtom);
  const isLoading = useAtomValue(isLoadingAtom);
  const isProcessing = useAtomValue(isProcessingAtom);
  const gotGeneratedImage = useAtomValue(gotGeneratedImageAtom);
  const generatedImageUrl = useAtomValue(generatedImageUrlAtom);

  const setPayloadWidth = useSetAtom(payloadWidthAtom);
  const setPayloadHeight = useSetAtom(payloadHeightAtom);

  useEffect(() => {
    if (gotGeneratedImage) {
      const img = new Image();

      img.src = generatedImageUrl;

      img.onload = () => {
        setPayloadWidth(img.width);
        setPayloadHeight(img.height);
      };
    }
  }, [
    size,
    generatedImageUrl,
    gotGeneratedImage,
    setPayloadWidth,
    setPayloadHeight,
  ]);

  // function handleOnEnter(e) {
  //   if (e.key === "Enter") {
  //     e.stopPropagation();
  //     getImage();
  //   }
  // }

  if (isLoading) {
    return <Loader label="Loading, this may take a few seconds..." />;
  }

  if (isProcessing) {
    return <></>;
  }

  return (
    <>
      <SizeSelector />

      <PromptInput
      // hasAutoPrompt={ false }
      />

      <NegativePromptInput />

      <StyleSelector />

      <div className="button-row">
        <Button
          tabIndex={0}
          label="Generate"
          onClick={getImage}
          // onKeyUp={(e) => handleOnEnter(e)}
          styles="btn-block btn-green"
          icon={<ImageIcon size={20} />}
        />

        {generatedImageUrl && <DownloadButton />}
      </div>
    </>
  );
}
