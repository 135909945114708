// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useRef } from "react";
import {
  advancedModeAtom,
  canvasHeightAtom,
  canvasWidthAtom,
  generatedImageUrlAtom,
  imageAtom,
  imageClientHeightAtom,
  isDrawingAtom,
  isMemberAtom,
  isProcessingAtom,
  menuOpenAtom,
  selectedStylesAtom,
  stripeStatusAtom,
  tabSelectedAtom,
  uploadedImageAtom,
} from "../../utils/initState";
import HelpText from "../HelpText";
import ImageLoader from "../ImageLoader";
import ProductCard from "../ProductCard";
import Tutorial from "../Tutorial";
import TutorialLoading from "../TutorialLoading";

export default function Layout({ headerContent, footerContent }) {
  const setImageClientHeight = useSetAtom(imageClientHeightAtom);
  const setCanvasWidth = useSetAtom(canvasWidthAtom);
  const setCanvasHeight = useSetAtom(canvasHeightAtom);

  const isMember = useAtomValue(isMemberAtom);
  const stripeStatus = useAtomValue(stripeStatusAtom);
  const isDrawing = useAtomValue(isDrawingAtom);
  const uploadedImage = useAtomValue(uploadedImageAtom);
  const generatedImageUrl = useAtomValue(generatedImageUrlAtom);
  const isProcessing = useAtomValue(isProcessingAtom);
  const menuOpen = useAtomValue(menuOpenAtom);
  const tabSelected = useAtomValue(tabSelectedAtom);
  const image = useAtomValue(imageAtom);
  const advancedMode = useAtomValue(advancedModeAtom);
  const selectedStyles = useAtomValue(selectedStylesAtom);

  const flexElemRef = useRef(null);
  const topElemRef = useRef(null);
  const middleElemRef = useRef(null);
  const bottomElemRef = useRef(null);
  const imgElemRef = useRef(null);

  function reportWindowSize() {
    if (
      flexElemRef.current &&
      topElemRef.current &&
      bottomElemRef.current &&
      middleElemRef.current &&
      imgElemRef.current
    ) {
      let flexHeight = flexElemRef.current.offsetHeight;
      let topHeight = topElemRef.current.offsetHeight;
      let bottomHeight = bottomElemRef.current.offsetHeight;

      let middleRef = middleElemRef.current;

      let imgHeight = imgElemRef.current.clientHeight;
      let imgWidth = imgElemRef.current.clientWidth;

      if (imgHeight === 0) {
        imgHeight = 450;
      }

      setImageClientHeight(imgHeight);

      middleRef.style.height = flexHeight - (bottomHeight + topHeight) + "px";
      middleRef.top = topHeight + "px";

      setCanvasHeight(imgHeight);
      setCanvasWidth(imgWidth);

      let newMidHeight = middleElemRef.current.offsetHeight;

      bottomElemRef.current.style.bottom = `${newMidHeight - imgHeight - 0}px`;
    }
  }

  function handleError(e) {
    if (/resizeobserver/.test(e?.message || e || "").toString()) {
      e.preventDefault();
    }
    e.stopImmediatePropagation();
  }

  useEffect(() => reportWindowSize());

  useEffect(() => {
    const observer = new ResizeObserver(() => reportWindowSize());

    observer.observe(flexElemRef.current);

    window.addEventListener("unhandledrejection", handleError);

    return () => {
      window.removeEventListener("unhandledrejection", handleError);
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected, image, advancedMode, selectedStyles, isDrawing]);

  // console.log(stripeStatus);

  return (
    <>
      <div ref={flexElemRef} className="flex-container" id="flex-container">
        <div ref={topElemRef} className="top-box" id="top-box">
          {headerContent}
        </div>

        {!isMember ? (
          stripeStatus === "failed" ? (
            <>
              <ProductCard
                label="Monthly plan"
                price="$19.99"
                actionLabel="failed"
              />

              <HelpText />
            </>
          ) : (
            <>
              <ProductCard
                label="Monthly plan"
                price="$19.99"
                actionLabel="3 Day Free Trial"
              />

              <HelpText />
            </>
          )
        ) : (
          <>
            <div ref={middleElemRef} className="middle-box" id="middle-box">
              <ImageLoader
                reportWindowSize={reportWindowSize}
                ref={imgElemRef}
              />

              {!menuOpen && isProcessing ? (
                <TutorialLoading />
              ) : (
                <>{!generatedImageUrl && !uploadedImage && <Tutorial />}</>
              )}
            </div>

            <div ref={bottomElemRef} className="bottom-box" id="bottom-box">
              {footerContent}
            </div>
          </>
        )}

        {/* {isMember && (
          <>
            <div ref={middleElemRef} className="middle-box" id="middle-box">
              <ImageLoader
                reportWindowSize={reportWindowSize}
                ref={imgElemRef}
              />

              {!menuOpen && isProcessing ? (
                <TutorialLoading />
              ) : (
                <>{!generatedImageUrl && !uploadedImage && <Tutorial />}</>
              )}
            </div>

            <div ref={bottomElemRef} className="bottom-box" id="bottom-box">
              {footerContent}
            </div>
          </>
        )} */}
      </div>
    </>
  );
}
