// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtomValue } from "jotai";
import { isMemberAtom } from "../../utils/initState";
import Brand from "../Brand";
import DropDown from "../Dropdown";
import Logo from "../Logo";
import MenuIcon from "../MenuIcon";
import TabBar from "../TabBar";
import UserGreeting from "../UserGreeting";

export default function Header() {
  const isMember = useAtomValue(isMemberAtom);

  return (
    <>
      <div className="header">
        <div className="header-wrapper">
          <Logo />

          <Brand />

          <UserGreeting />

          <MenuIcon />

          <DropDown />
        </div>
      </div>

      {isMember && <TabBar />}
    </>
  );
}
