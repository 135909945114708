// =================================================================================================== //
// █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import axios from "axios";
import { useAtom } from "jotai";
import toast from "react-hot-toast";
import {
  enhancedPromptLoadingAtom,
  previouslyEnhancedPromptAtom,
  promptAtom,
} from "../../utils/initState";
import {
  toastError,
  // toastError,
  toastSuccess,
} from "../../utils/toastStyles";
import { StarsIcon } from "../Icons";

export default function AutoPrompt() {
  const [prompt, setPrompt] = useAtom(promptAtom);
  const [enhancedPromptLoading, setEnhancedPromptLoading] = useAtom(
    enhancedPromptLoadingAtom,
  );

  const [previouslyEnhancedPrompt, setPreviouslyEnhancedPrompt] = useAtom(
    previouslyEnhancedPromptAtom,
  );

  const apiKey = process.env.REACT_APP_API_KEY;

  async function enhancePrompt() {
    setPreviouslyEnhancedPrompt(prompt);
    setEnhancedPromptLoading(true);

    if (prompt) {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/enhanceprompt`,
          { prompt: prompt },
          {
            headers: {
              "Content-Type": "application/json",
              "X-API-KEY": apiKey,
            },
            maxBodyLength: Infinity,
            maxContentLength: Infinity,
          },
        );
        // console.log(res.data);

        setPrompt(res.data.enhancedPrompt);

        setPreviouslyEnhancedPrompt(res.data.enhancedPrompt);

        setEnhancedPromptLoading(false);

        toast.success("Prompt enhanced!", {
          duration: 2000,
          style: toastSuccess,
        });
      } catch (err) {
        console.error(err);

        setEnhancedPromptLoading(false);

        toast.error("Error optimizing prompt!", {
          duration: 2000,
          style: toastError,
        });
      }
    }
  }

  function isDisabled() {
    if (
      !prompt ||
      enhancedPromptLoading ||
      previouslyEnhancedPrompt === prompt
    ) {
      return true;
    } else {
      return false;
    }
  }

  function getClassName() {
    if (!prompt || previouslyEnhancedPrompt === prompt) {
      return "auto-prompt-disabled";
    } else {
      return "";
    }
  }

  return (
    <>
      <div className="auto-prompt-divider" />
      <button
        disabled={isDisabled()}
        onClick={enhancePrompt}
        className="auto-prompt-button"
      >
        {enhancedPromptLoading ? (
          <span className="auto-prompt-loader" />
        ) : (
          <span className={getClassName()}>
            <StarsIcon size={24} />
          </span>
        )}
      </button>
    </>
  );
}
