// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
export default function Loader({ label }) {
  return (
    <div className="loader-wrapper">
      <span className="loader" />
      {label && <h4>{label}</h4>}
    </div>
  );
}
