// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtom, useSetAtom } from "jotai";
import {
  brushSizeAtom,
  clearCanvasAtom,
  selectedBrushElementAtom,
} from "../../utils/initState";
import { CloseIcon, DrawIcon, EraseIcon } from "../Icons";

export default function BrushSelector() {
  return (
    <div className="size-selector-wrapper">
      <BrushElement type="draw" label="Draw" icon={<DrawIcon size={18} />} />

      <BrushSizeElement />

      <BrushElement type="erase" label="Erase" icon={<EraseIcon size={18} />} />

      <BrushClearElement label={"Clear"} icon={<CloseIcon size={18} />} />
    </div>
  );
}

function BrushElement({ type, label, icon }) {
  const [selectedBrushElement, setSelectedBrushElement] = useAtom(
    selectedBrushElementAtom,
  );

  function handleBrushElementSelect() {
    setSelectedBrushElement(type);
  }

  function handleOnEnter(e) {
    if (e.key === "Enter") {
      e.stopPropagation();
      setSelectedBrushElement(type);
    }
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyUp={(e) => handleOnEnter(e)}
      onClick={handleBrushElementSelect}
      ariaLabel={`Select ${label ?? type}`}
      className={`size-selector-section ${
        selectedBrushElement === type && "size-selector-section-active"
      }`}
    >
      <div className="size-selector-section-icon">{icon}</div>
      {label ?? type}
    </div>
  );
}

function BrushSizeElement() {
  const [brushSize, setBrushSize] = useAtom(brushSizeAtom);

  return (
    <div className="size-selector-section">
      <input
        type="range"
        min={6}
        max={50}
        value={brushSize}
        className="slider"
        onChange={(e) => setBrushSize(e.target.value)}
      />
    </div>
  );
}

function BrushClearElement({ label, icon }) {
  const setClearCanvas = useSetAtom(clearCanvasAtom);

  function handleOnEnter(e) {
    if (e.key === "Enter") {
      e.stopPropagation();
      setClearCanvas(true);
    }
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyUp={(e) => handleOnEnter(e)}
      onClick={() => setClearCanvas(true)}
      className="size-selector-section size-selector-button"
    >
      <div className="size-selector-section-icon">{icon}</div>
      {label}
    </div>
  );
}
