// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
export default function TutorialItem({
  link,
  title,
  contentFirst,
  contentSecond,
}) {
  return (
    <div className="tutorial">
      {title && <h1>{title}</h1>}

      {contentFirst && <p>{contentFirst}</p>}

      {contentSecond && <p>{contentSecond}</p>}

      {link && (
        <a tabIndex={0} target="_blank" rel="noreferrer" href={link}>
          Detailed tutorial here
        </a>
      )}
    </div>
  );
}
