// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtomValue } from "jotai";
import { tabSelectedAtom } from "../../utils/initState";
import Image2Image from "../Image2Image";
import Paint from "../Paint";
import Text2Image from "../Text2Image";
import Upscale from "../Upscale";

export default function TabView() {
  const tabSelected = useAtomValue(tabSelectedAtom);

  return (
    <div className="tab-view">
      <div className="tab-view-content">
        {tabSelected === 1 && <Text2Image />}
        {tabSelected === 2 && <Image2Image />}
        {tabSelected === 3 && <Paint />}
        {tabSelected === 4 && <Upscale />}
      </div>
    </div>
  );
}
