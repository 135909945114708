// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtomValue } from "jotai";
import { imageProgressAtom, queueInfoAtom } from "../../utils/initState";

export default function TutorialLoading() {
  const imageProgress = useAtomValue(imageProgressAtom);
  const queueInfo = useAtomValue(queueInfoAtom);

  return (
    <div className="tutorial">
      <h1>{queueInfo ? queueInfo : `Processing... ${imageProgress}%`}</h1>

      <div className="tutorial-loading">
        <span
          style={{
            left: 0,
            height: "100%",
            position: "absolute",
            background: "#94db77",
            width: imageProgress + "%" || "0%",
            transition: "width 200ms cubic-bezier(.33,.08,.47,.93)",
          }}
        />
      </div>
    </div>
  );
}
