// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtom } from "jotai";
import { promptAtom } from "../../utils/initState";
import AutoPrompt from "../AutoPrompt";

export default function PromptInput() {
  // { hasAutoPrompt }
  const [prompt, setPrompt] = useAtom(promptAtom);

  return (
    <div className="prompt-input-wrapper">
      <input
        required
        tabIndex={0}
        type="text"
        value={prompt}
        maxLength={320}
        className="prompt-input"
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Enter a prompt here (example: a cute dog)"
      />
      {/* {hasAutoPrompt &&  */}
      <AutoPrompt />
      {/* } */}
    </div>
  );
}
