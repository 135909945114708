// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

// ======================//
//    GENERAL PURPOSE    //
// ======================//
export const appLoadingAtom = atom(false); //==================[Initial app load]
export const isLoadingAtom = atom(false); //===================[General purpose loading state]
export const isBannedAtom = atom(false); //====================[General purpose shitbag state]
export const userAtom = atom(null); //=========================[Stored firebase user object]
export const isMemberAtom = atom(false); //====================[Is subscribed member]
export const stripeStatusAtom = atom("never_subscribed"); //===[Stripe payment status]
export const pageTitleAtom = atom("ArtForge Labs, Inc."); //===[Is subscribed member]
export const themeAtom = atomWithStorage("theme", "light"); //=[Color theme mode]
export const menuOpenAtom = atom(false); //====================[Handles user menu visibility]
export const shareOpenAtom = atom(false); //===================[Handles share menu visibility]
export const advancedModeAtom = atom(false); // ===============[Advanced mode setting]
export const tabSelectedAtom = atom(1); //=====================[Selected tab view]
export const isProcessingAtom = atom(false); //================[Processing state of a running task]
export const enhancedPromptLoadingAtom = atom(false); //==========[Processing state of enhanced prompt feature]
export const previouslyEnhancedPromptAtom = atom(""); //==========[Processing state of enhanced prompt feature]

// ==============//
//    PROMPTS    //
// ==============//
export const promptAtom = atom(""); //=========================[Prompt text]
export const negativePromptAtom = atom(""); //=================[Negative prompt text]
export const previousPromptAtom = atom(""); //=================[Previously submitted prompt]

// =============//
//    IMAGES    //
// =============//
export const imageClientHeightAtom = atom(500); //=============[Progress of current task]
export const imageProgressAtom = atom(0); //===================[Progress of current task]
export const gotNewImageRefAtom = atom(false); //==============[Handles the image container ref update check]
export const prevImageRefAtom = atom("hey wubs"); //===========[Handles the image container ref update check]
export const generatedImageUrlAtom = atom(""); //==============[Url from processed image]
export const imageAtom = atom(""); //==========================[Handles all displayed images]
export const uploadedImageAtom = atom(""); //==================[User uploaded image]
export const uploadedImageNameAtom = atom(""); //==============[User uploaded image name]
export const uploadedImageWidthAtom = atom(""); //=============[User uploaded image width]
export const uploadedImageHeightAtom = atom(""); //============[User uploaded image height]
export const scaleAtom = atom(1); //===========================["Fit in the box" image resizing scale]
export const gotGeneratedImageAtom = atom(false); //===========[Determines if an image was generated]
export const hasUpscaledImageAtom = atom(false); //============[Has the user upscaled an image]
export const imageRectXAtom = atom(0); //======================[Left coordinate of image from image loader]
export const imageRectYAtom = atom(0); //======================[Top coordinate of image from image loader]
export const payloadWidthAtom = atom(1024); //=================[Image width of the payload to send]
export const payloadHeightAtom = atom(1024); //================[Image Height of the payload to send]

// ============//
//    QUEUE    //
// ============//
export const queueInfoAtom = atom(""); //======================[Displays queue info]

// ==============//
//    BUTTONS    //
// ==============//

// ==========================//
//    CREATIVITY SELECTOR    //
// ==========================//
export const selectedCreativeElementAtom = atom(0.6); //=======[Selected denoising strength value]

// =====================//
//    STYLE SELECTOR    //
// =====================//
export const selectedStylesAtom = atom([]); //=================[Styles selected from the style menu]
export const isStyleSelecterOpenAtom = atom(false); //=========[Style selector visibility]
export const styleSelecterHighlightedIndexAtom = atom(); //====[Style selector selected state]
export const selectedSizeAtom = atom("square"); //=============[Style selector selected state]

// =================//
//    CANVAS        //
// =================//
export const isDrawingAtom = atom(false); //===================[Is the user currently drawing]
export const hasDrawnAtom = atom(false); //====================[Has the user drawn anything yet]
export const brushSizeAtom = atom(12); //======================[Size of the brush element]
export const selectedBrushElementAtom = atom("draw"); //=======[Is "draw" or "erase" selected]
export const canvasHeightAtom = atom(0); //====================[Height of the drawable canvas]
export const canvasWidthAtom = atom(0); //=====================[Width of the drawable canvas]
export const canvasImageUrlAtom = atom(""); //=================[ImageURL from canvas drawing]
export const clearCanvasAtom = atom(false); //=================[Should the canvas be cleared]
// =================//
//    TESTING       //
// =================//
export const debugTempCanvasWidthAtom = atom(0); //=============[Should the canvas be cleared]
export const debugTempCanvasHeightAtom = atom(0); //============[Should the canvas be cleared]

// Legacy
// export const sockOpenAtom = atom(false); //====================[Web socket status]
