// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import gameArt2d from "../images/2dGameArt.webp";
import model3d from "../images/3d-model.webp";
import animation3d from "../images/3dAnimation.webp";
import cubism from "../images/Cubism.webp";
import flames from "../images/Flames.webp";
import graffiti from "../images/Graffiti.webp";
import legendary from "../images/Legendary.webp";
import macroPhotography from "../images/Macro.webp";
import noir from "../images/Noir.webp";
import photographic from "../images/Photographic.webp";
import zombified from "../images/Zombified.webp";
import analogFilm from "../images/analogfilm.webp";
import anime from "../images/anime.webp";
import biomechanical from "../images/biomechanical.webp";
import cinematic from "../images/cinematic.webp";
import comicBook from "../images/comicbook.webp";
import craftClay from "../images/craftclay.webp";
import digitalArt from "../images/digitalart.webp";
import enhance from "../images/enhance.webp";
import fantasyArt from "../images/fantasyart.webp";
import isometric from "../images/isometric.webp";
import kawaii from "../images/kawaii.webp";
import lineArt from "../images/lineart.webp";
import lovecraftian from "../images/lovecraftian.webp";
import lowpoly from "../images/lowpoly.webp";
import neonpunk from "../images/neonpunk.webp";
import oilPainting from "../images/oilpainting.webp";
import origami from "../images/origami.webp";
import pixelArt from "../images/pixelart.webp";

// Sorts the styles list alphabetically
function sortName(a, b) {
  return a.label.localeCompare(b.label);
}

export const newStyles = [
  {
    id: "Enhance",
    label: "Enhance",
    image: enhance,
    override: {},
  },
  {
    id: "Anime",
    label: "Anime",
    image: anime,
    override: {},
  },
  {
    id: "Photographic",
    label: "Photographic",
    image: photographic,
    override: {},
  },
  {
    id: "Cinematic",
    label: "Cinematic",
    image: cinematic,
    override: {},
  },
  {
    id: "Digital art",
    label: "Digital art",
    image: digitalArt,
    override: {},
  },
  {
    id: "Comic book",
    label: "Comic book",
    image: comicBook,
    override: {},
  },
  {
    id: "Fantasy art",
    label: "Fantasy art",
    image: fantasyArt,
    override: {},
  },
  {
    id: "Analog film",
    label: "Analog film",
    image: analogFilm,
    override: {},
  },
  {
    id: "Neonpunk",
    label: "Neon punk",
    image: neonpunk,
    override: {},
  },
  {
    id: "Isometric",
    label: "Isometric",
    image: isometric,
    override: {},
  },
  {
    id: "Lowpoly",
    label: "Lowpoly",
    image: lowpoly,
    override: {},
  },
  {
    id: "Origami",
    label: "Origami",
    image: origami,
    override: {},
  },
  {
    id: "Line art",
    label: "Line art",
    image: lineArt,
    override: {},
  },
  {
    id: "Craft clay",
    label: "Craft clay",
    image: craftClay,
    override: {},
  },
  {
    id: "3D Model",
    label: "3D Model",
    image: model3d,
    override: {},
  },
  {
    id: "Pixel art",
    label: "Pixel art",
    image: pixelArt,
    override: { refiner_switch_at: 1 },
  },
  {
    id: "2D Game Art",
    label: "2D Game Art",
    image: gameArt2d,
    override: {},
  },
  {
    id: "3D Animation",
    label: "3D Animation",
    image: animation3d,
    override: {},
  },
  {
    id: "Zombified",
    label: "Zombified",
    image: zombified,
    override: {},
  },
  {
    id: "Cubism",
    label: "Cubism",
    image: cubism,
    override: {},
  },
  {
    id: "Noir",
    label: "Noir",
    image: noir,
    override: {},
  },
  {
    id: "Graffiti Art",
    label: "Graffiti",
    image: graffiti,
    override: {},
  },
  {
    id: "Lovecraftian",
    label: "Lovecraftian",
    image: lovecraftian,
    override: {},
  },
  {
    id: "Flames",
    label: "Flames",
    image: flames,
    override: {},
  },
  {
    id: "Macro Photography",
    label: "Macro",
    image: macroPhotography,
    override: {},
  },
  {
    id: "Oil Painting",
    label: "Oil Painting",
    image: oilPainting,
    override: {},
  },
  {
    id: "Biomechanical",
    label: "Biomech",
    image: biomechanical,
    override: {},
  },
  {
    id: "Legendary Fantasy",
    label: "Legendary",
    image: legendary,
    override: {},
  },
  {
    id: "Kawaii",
    label: "Kawaii",
    image: kawaii,
    override: {},
  },
].sort(sortName);
