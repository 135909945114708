// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
export default function Button({
  label,
  disabled,
  onClick,
  styles,
  type,
  icon,
  tabIndex,
  onKeyUp,
}) {
  return (
    <button
      tabIndex={tabIndex}
      onClick={onClick}
      onKeyUp={onKeyUp}
      disabled={disabled}
      type={type ?? "button"}
      className={`button ${styles}`}
    >
      {label}
      {icon && <div className="btn-icon">{icon}</div>}
    </button>
  );
}
