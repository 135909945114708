// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import toast from "react-hot-toast";
import { getImageSize, getUuid } from "../utils/helpers";
import {
  canvasImageUrlAtom,
  generatedImageUrlAtom,
  gotGeneratedImageAtom,
  hasUpscaledImageAtom,
  imageProgressAtom,
  isProcessingAtom,
  negativePromptAtom,
  payloadHeightAtom,
  payloadWidthAtom,
  previousPromptAtom,
  promptAtom,
  queueInfoAtom,
  selectedCreativeElementAtom,
  selectedSizeAtom,
  selectedStylesAtom,
  tabSelectedAtom,
  uploadedImageAtom,
  uploadedImageNameAtom,
  userAtom,
} from "../utils/initState";
import { toastError } from "../utils/toastStyles";

export default function useWebsocket() {
  const [generatedImageUrl, setGeneratedImageUrl] = useAtom(
    generatedImageUrlAtom,
  );

  const user = useAtomValue(userAtom);
  const prompt = useAtomValue(promptAtom);
  const size = useAtomValue(selectedSizeAtom);
  const tabSelected = useAtomValue(tabSelectedAtom);
  const payloadWidth = useAtomValue(payloadWidthAtom);
  const payloadHeight = useAtomValue(payloadHeightAtom);
  const uploadedImage = useAtomValue(uploadedImageAtom);
  const negativePrompt = useAtomValue(negativePromptAtom);
  const selectedStyles = useAtomValue(selectedStylesAtom);
  const canvasImageUrl = useAtomValue(canvasImageUrlAtom);
  const selectedCreativeElement = useAtomValue(selectedCreativeElementAtom);

  const setQueueInfo = useSetAtom(queueInfoAtom);
  const setIsProcessing = useSetAtom(isProcessingAtom);
  const setUploadedImage = useSetAtom(uploadedImageAtom);
  const setImageProgress = useSetAtom(imageProgressAtom);
  const setPreviousPrompt = useSetAtom(previousPromptAtom);
  const setHasUpscaledImage = useSetAtom(hasUpscaledImageAtom);
  const setGotGeneratedImage = useSetAtom(gotGeneratedImageAtom);
  const setUploadedImageName = useSetAtom(uploadedImageNameAtom);

  function getAppendedStyles() {
    let appendedStyles = [];

    selectedStyles.map((style) => appendedStyles.push(`Style: ${style}`));

    return appendedStyles;
  }

  function getImage() {
    const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}`);

    const { width, height } = getImageSize(size);

    const text2imgPayload = {
      task_id: getUuid(),
      width: width,
      height: height,
      prompt: prompt,
      styles: getAppendedStyles(),
      // === Leave these defaults === //
      negative_prompt: negativePrompt ? negativePrompt : "",
      sampler_name: "DPM++ 2M SDE Karras",
      batch_size: 1,
      restore_faces: false,
      tiling: false,
      do_not_save_samples: false,
      do_not_save_grid: false,
      n_iter: 1,
      enable_hr: false,
      hr_scale: 2,
      sampler_index: "Euler",
      send_images: true,
      save_images: false,
      disable_extra_networks: false,

      // SDXL
      // steps: 40,
      // cfg_scale: 7,
      // refiner_checkpoint: "sd_xl_refiner_1.0",
      // refiner_switch_at: 0.8,
      // sd_model_checkpoint: "sd_xl_base_1.0.safetensors",

      // SDXL Turbo
      refiner_switch_at: 1.0,
      steps: 10,
      cfg_scale: 1.5,
      sd_model_checkpoint: "ultraspiceXLTURBO_ultraspiceV04.safetensors",
    };

    const img2imgPayload = {
      // === Reactive values === //
      task_id: getUuid(),
      init_images: generatedImageUrl ? [generatedImageUrl] : [uploadedImage],
      seed: -1,
      denoising_strength: selectedCreativeElement,
      width: payloadWidth,
      height: payloadHeight,
      prompt: prompt,
      styles: getAppendedStyles(),
      negative_prompt: negativePrompt ? negativePrompt : "",
      sampler_index: "Euler",
      // SDXL
      //steps: 40,
      //cfg_scale: 20,

      // SDXL Turbo
      steps: 10,
      cfg_scale: 1.5,
    };

    const paintPayload = {
      // === Image 2 Image === //
      task_id: getUuid(),
      init_images: generatedImageUrl ? [generatedImageUrl] : [uploadedImage],
      seed: -1,
      denoising_strength: selectedCreativeElement,
      width: 1024,
      height: 1024,
      prompt: prompt,
      styles: getAppendedStyles(),
      negative_prompt: negativePrompt ? negativePrompt : "",
      sampler_index: "Euler",
      // In-Painting
      mask: canvasImageUrl, // Canvas mask
      resize_mode: 1,
      mask_blur: 16, // Feathered edge
      inpainting_fill: 1,
      inpaint_full_res: true,
      inpaint_full_res_padding: 32,
      inpainting_mask_invert: 0,
      // SDXL
      // steps: 40,
      // cfg_scale: 20,

      // SDXL Turbo
      steps: 10,
      cfg_scale: 1.5,
    };

    const upscalePayload = {
      // === Reactive values === //
      task_id: getUuid(),
      init_images: generatedImageUrl ? [generatedImageUrl] : [uploadedImage],
      seed: -1,
      denoising_strength: 0.05,
      width: payloadWidth * 2,
      height: payloadHeight * 2,
      sampler_index: "Euler",
      script_name: "ultimate sd upscale",
      script_args: [
        null, // _ (not used)
        1024, // tile_width
        1024, // tile_height
        8, // mask_blur
        32, // padding
        64, // seams_fix_width
        0.35, // seams_fix_denoise
        32, // seams_fix_padding
        5, // upscaler_index
        true, // save_upscaled_image a.k.a Upscaled
        0, // redraw_mode
        false, // save_seams_fix_image a.k.a Seams fix
        8, // seams_fix_mask_blur
        0, // seams_fix_type
        0, // target_size_type
        2048, // custom_width
        2048, // custom_height
        2, // custom_scale
      ],

      // SDXL
      // cfg_scale: 20,
      // steps: 30,

      // SDXL Turbo
      steps: 10,
      cfg_scale: 1.5,
    };

    ws.onerror = (e) => {
      toastError.error(
        <span>
          A websocket error occured
          <a
            target="_blank"
            rel="noreferrer"
            className="toast-link"
            href="https://www.artforgelabs.com/post/what-errors-mean-in-art-forge"
          >
            Click here
          </a>{" "}
          for more info
        </span>,
        {
          duration: 4000,
          style: toastError,
        },
      );
    };

    ws.onmessage = (e) => {
      const { dataType, data } = JSON.parse(e.data);

      let feature;
      let payload;

      switch (tabSelected) {
        case 1:
          feature = "txt2img";
          payload = text2imgPayload;
          break;

        case 2:
          feature = "img2img";
          payload = img2imgPayload;
          break;

        case 3:
          feature = "img2img";
          payload = paintPayload;
          break;

        case 4:
          feature = "img2img";
          payload = upscalePayload;
          break;

        default:
          feature = "txt2img";
          payload = text2imgPayload;
          break;
      }

      if (dataType === "connected") {
        setGeneratedImageUrl("");
        setIsProcessing(true);

        ws.send(
          JSON.stringify({
            feature,
            userId: user.uid,
            dataType: "payload",
            data: { payload, clientId: data },
          }),
        );
      }

      if (dataType === "return image") {
        // Generate
        setQueueInfo("");
        setPreviousPrompt(prompt);
        setGeneratedImageUrl(data);
        setImageProgress(0);
        setIsProcessing(false);
        document.title = "ArtForge Labs, Inc.";

        // Refine && Paint
        setGotGeneratedImage(true);
        setUploadedImage("");
        setUploadedImageName("");

        if (tabSelected === 4) {
          setHasUpscaledImage(true);
        } else {
          setHasUpscaledImage(false);
        }

        ws.close();
      }

      if (dataType === "refusal") {
        // Handle refusal
        setQueueInfo("");
        setGeneratedImageUrl("");
        setImageProgress(0);
        setIsProcessing(false);
        document.title = "ArtForge Labs, Inc.";

        ws.close();

        toast.error("You already have a task in queue", {
          duration: 4000,
          style: toastError,
        });
      }

      if (dataType === "status update") {
        setQueueInfo(data);
        document.title = `${data} | ArtForge Labs ⏳`;
      }

      if (dataType === "progress update") {
        setImageProgress(Math.floor(data * 100));
        document.title = `${Math.floor(data * 100)}% | ArtForge Labs 🎨`;

        if (data > 0) {
          setQueueInfo("");
        }
      }

      if (dataType === "error") {
        // Generate
        setQueueInfo("");
        setGeneratedImageUrl("");
        setImageProgress(0);
        setIsProcessing(false);
        document.title = "ArtForge Labs, Inc.";

        // Refine && Paint
        setGotGeneratedImage(true);
        setUploadedImage("");
        setUploadedImageName("");

        ws.close();

        toast.error(
          <span>
            Something went wrong.{" "}
            <a
              target="_blank"
              rel="noreferrer"
              className="toast-link"
              href="https://www.artforgelabs.com/post/what-errors-mean-in-art-forge"
            >
              Click here
            </a>{" "}
            for more info
          </span>,
          {
            duration: 4000,
            style: toastError,
          },
        );
      }
    };
  }

  return { getImage };
}
