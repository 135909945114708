// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import toast from "react-hot-toast";
import { auth } from "../../firebase";
import { toastError } from "../../utils/toastStyles";
import { GoogleIcon } from "../Icons";

export default function GoogleButton() {
  const provider = new GoogleAuthProvider();

  //=== Sign in using Google and firebase ===//
  function handleSignIn() {
    signInWithPopup(auth, provider).catch((error) => {
      toast.error(error, {
        duration: 5000,
        style: toastError,
      });
    });
  }

  return (
    <div className="sign-in-button-wrapper">
      <button
        tabIndex={0}
        type="button"
        className="button google-btn"
        onClick={handleSignIn}
      >
        <span className="btn-icon">
          <GoogleIcon size={24} />
        </span>
        Sign in with Google
      </button>
    </div>
  );
}
