// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtomValue } from "jotai";
import { tabSelectedAtom } from "../../utils/initState";
import TutorialItem from "../TutorialItem";

export default function Tutorial() {
  const tabSelected = useAtomValue(tabSelectedAtom);

  return (
    <>
      {tabSelected === 1 && (
        <TutorialItem
          title="Generate:"
          contentFirst="Generate image from simple text prompts."
          contentSecond="Choose none, one, or multiple styles for customization."
          link="https://www.artforgelabs.com/post/step-by-step-guide-to-creating-your-first-ai-generated-artwork"
        />
      )}

      {tabSelected === 2 && (
        <TutorialItem
          title="Refine:"
          contentFirst="Use text prompts to direct the image's refinement."
          contentSecond="Add styles for further personalization."
          link="https://www.artforgelabs.com/post/how-to-use-the-refine-tool-with-art-forge-labs"
        />
      )}

      {tabSelected === 3 && (
        <TutorialItem
          title="Paint:"
          contentFirst="Blend new elements into your image."
          contentSecond="Mask an area for the AI to regenerate."
          link="https://www.artforgelabs.com/post/mastering-inpainting-with-art-forge-labs"
        />
      )}

      {tabSelected === 4 && (
        <TutorialItem
          title="Upscale:"
          contentFirst="Upscale will double your image's resolution."
          contentSecond="Maximum upload size is 1536 x 1536"
          link="https://artforgelabs.com/post/elevate-your-images-discover-the-power-of-ai-upscaling-with-art-forge-labs"
        />
      )}
    </>
  );
}
