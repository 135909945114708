// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtomValue } from "jotai";
import toast from "react-hot-toast";
import { getFileName } from "../../utils/helpers";
import {
  generatedImageUrlAtom,
  previousPromptAtom,
} from "../../utils/initState";
import { toastSuccess } from "../../utils/toastStyles";
import { DownloadIcon } from "../Icons";

export default function DownloadButton() {
  const generatedImageUrl = useAtomValue(generatedImageUrlAtom);
  const previousPrompt = useAtomValue(previousPromptAtom);

  // function handleOnEnter(e) {
  //   if (e.key === "Enter") {
  //     toast.success(
  //       `Saving image ${
  //         previousPrompt ? getFileName(previousPrompt) : "my-image.png"
  //       }`,
  //       {
  //         duration: 3000,
  //         style: toastSuccess,
  //       },
  //     );
  //     e.stopPropagation();
  //   }
  // }

  return (
    <a
      role="button"
      href={generatedImageUrl}
      className="button btn-block btn-dark"
      // onKeyUp={(e) => handleOnEnter(e)}
      onClick={() => {
        toast.success(
          `Saving image ${
            previousPrompt ? getFileName(previousPrompt) : "my-image.png"
          }`,
          {
            duration: 3000,
            style: toastSuccess,
          },
        );
      }}
      download={
        previousPrompt
          ? previousPrompt?.replace(/\W/g, "") + ".png"
          : "my-image.png"
      }
    >
      Download
      <span className="btn-icon">
        <DownloadIcon size={20} />
      </span>
    </a>
  );
}
