// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import {
  addDoc,
  and,
  collection,
  doc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app, auth, db } from "./firebase";

export async function getCheckoutUrl(stripeStatus) {
  const userId = auth.currentUser?.uid;

  let priceLink = process.env.REACT_APP_STRIPE_PRICE_LINK;

  if (!userId) {
    throw new Error("User is not authenticated");
  }

  if (stripeStatus === "failed") {
    priceLink = process.env.REACT_APP_STRIPE_PRICE_LINK_FAILED;
  }

  const checkoutSessionRef = collection(
    db,
    "customers",
    userId,
    "checkout_sessions",
  );

  const docRef = await addDoc(checkoutSessionRef, {
    price: priceLink,
    allow_promotion_codes: true,
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  });

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      const { error, url } = snapshot.data();

      if (error) {
        unsubscribe();
        reject(new Error(`An error occurred: ${error.message}`));
      }

      if (url) {
        unsubscribe();
        resolve(url);
      }
    });
  });
}

export async function getPortalUrl() {
  const user = auth.currentUser;

  let dataWithUrl;

  try {
    const cloudFunctions = getFunctions(app, "us-east4"); // Virginia

    const functionRef = httpsCallable(
      cloudFunctions,
      "ext-firestore-stripe-payments-createPortalLink",
    );

    const { data } = await functionRef({
      customerId: user?.uid,
      returnUrl: window.location.origin,
    });

    dataWithUrl = data;
  } catch (error) {
    console.error(error);
  }

  return new Promise((resolve, reject) => {
    if (dataWithUrl.url) {
      resolve(dataWithUrl.url);
    } else {
      reject(new Error("No url returned"));
    }
  });
}

export async function getMembershipStatus() {
  const userId = auth.currentUser?.uid;

  if (!userId) {
    throw new Error("User not logged in");
  }

  const subscriptionsRef = collection(db, "customers", userId, "subscriptions");

  const productRef = doc(
    db,
    `products/${process.env.REACT_APP_STRIPE_PRODUCT_ID}`,
  );

  const _query = query(
    subscriptionsRef,
    and(
      where("status", "in", [
        "trialing",
        "active",
        "incomplete",
        "incomplete_expired",
        "past_due",
        "canceled",
        "unpaid",
      ]),
      where("product", "==", productRef),
    ),
  );

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(
      _query,
      (snapshot) => {
        // console.log(snapshot.docs[0].data().status);

        let hasActive = false;

        if (snapshot.docs.length === 0) {
          resolve("never_subscribed");
        } else {
          snapshot.docs.forEach((doc) => {
            const status = doc.data().status;

            if (status === "active" || status === "trialing") {
              hasActive = true;
            }
          });

          if (hasActive) {
            resolve("subscribed");
            // resolve("failed");
          } else {
            resolve("failed");
          }
        }

        unsubscribe();
      },
      reject,
    );
  });
}
