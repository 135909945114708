// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { Landscape, Portrait, Square, UltraWide } from "../Icons";
import SizeElement from "../SizeElement";

export default function SizeSelector() {
  return (
    <div className="size-selector-wrapper">
      <SizeElement
        icon={
          <Square
            size={12} // We used 12 instead of 14 because it looks better
          />
        }
        size="square"
        label="Square"
      />
      <SizeElement
        icon={<Portrait size={14} />}
        size="portrait"
        label="Portrait"
      />
      <SizeElement
        icon={<Landscape size={14} />}
        size="landscape"
        label="Landscape"
      />
      <SizeElement
        icon={<UltraWide size={14} />}
        size="ultra-wide"
        label="Ultra wide"
      />
    </div>
  );
}
