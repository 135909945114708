// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { LogoIcon } from "../Icons";

export default function Logo() {
  return (
    <a className="logo" tabIndex={0} href="https://artforgelabs.com">
      <LogoIcon size={55} />
    </a>
  );
}
