// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { v4 as uuid } from "uuid";

// Gets the size from the size selector
export function getImageSize(size) {
  let width = 768;
  let height = 768;

  if (size === "square") {
    width = 768;
    height = 768;
  }
  if (size === "portrait") {
    width = 728;
    height = 1080;
  }
  if (size === "landscape") {
    width = 1080;
    height = 728;
  }
  if (size === "ultra-wide") {
    width = 1128;
    height = 488;
  }

  return { width, height };
}

// Generates a task id
export function getUuid() {
  const newUuid = uuid();

  return newUuid;
}

// Converts a string to a 16 character file name with ".png"
export function getFileName(str) {
  return str.substring(0, 16)?.replace(/\W/g, "") + ".png";
}

// Truncate a string with a supplied max length
export function truncateString(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  } else {
    return str;
  }
}

// Converts a file(image in this case) to base64
export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
