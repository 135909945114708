// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { signOut } from "firebase/auth";
import { useSetAtom } from "jotai";
import Button from "../components/Button";
import { LogoIcon, SignOutIcon } from "../components/Icons";
import { auth } from "../firebase";
import { getPortalUrl } from "../stripePayment";
import { isLoadingAtom } from "../utils/initState";

export default function BannedPage() {
  const setIsLoading = useSetAtom(isLoadingAtom);

  function handleSignOut() {
    signOut(auth);
    window.location.reload();
  }

  async function manageMembership() {
    setIsLoading(true);
    const portalUrl = await getPortalUrl();

    window.location.href = portalUrl;
  }

  return (
    <div className="login-page">
      <div className="login-page-wrapper">
        <div className="login-page-logo">
          <LogoIcon size={200} />
        </div>

        <div className="sign-in-text">
          <h3>
            You've been banned for a{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.artforgelabs.com/post/art-forge-labs-terms-of-service"
            >
              terms of service{" "}
            </a>
            violation
          </h3>
        </div>

        <Button
          tabIndex={0}
          styles="btn-block btn-red"
          label="Manage membership"
          onClick={manageMembership}
        />

        <Button
          tabIndex={0}
          label="Sign out"
          styles="btn-block btn-small btn-dark text-red hover-red"
          onClick={handleSignOut}
          // onKeyUp={handleSignOutOnEnter}
          icon={<SignOutIcon size={20} />}
        />
      </div>
    </div>
  );
}
