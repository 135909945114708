// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import toast from "react-hot-toast";
import useWebsocket from "../../hooks/useWebsocket";
import { getImageSize } from "../../utils/helpers";
import {
  generatedImageUrlAtom,
  gotGeneratedImageAtom,
  hasUpscaledImageAtom,
  isLoadingAtom,
  isProcessingAtom,
  payloadHeightAtom,
  payloadWidthAtom,
  scaleAtom,
  selectedSizeAtom,
  uploadedImageAtom,
  uploadedImageHeightAtom,
  uploadedImageNameAtom,
  uploadedImageWidthAtom,
} from "../../utils/initState";
import { toastError } from "../../utils/toastStyles";
import Button from "../Button";
import DownloadButton from "../DownloadButton";
import { ImageIcon } from "../Icons";
import Loader from "../Loader";
import UploadImage from "../UploadImage";

export default function Upscale() {
  const { getImage } = useWebsocket();

  const [scale, setScale] = useAtom(scaleAtom);

  const setPayloadWidth = useSetAtom(payloadWidthAtom);
  const setPayloadHeight = useSetAtom(payloadHeightAtom);
  const setUploadedImage = useSetAtom(uploadedImageAtom);
  const setUploadedImageName = useSetAtom(uploadedImageNameAtom);

  const size = useAtomValue(selectedSizeAtom);
  const isLoading = useAtomValue(isLoadingAtom);
  const isProcessing = useAtomValue(isProcessingAtom);
  const uploadedImage = useAtomValue(uploadedImageAtom);
  const hasUpscaledImage = useAtomValue(hasUpscaledImageAtom);
  const generatedImageUrl = useAtomValue(generatedImageUrlAtom);
  const gotGeneratedImage = useAtomValue(gotGeneratedImageAtom);
  const uploadedImageWidth = useAtomValue(uploadedImageWidthAtom);
  const uploadedImageHeight = useAtomValue(uploadedImageHeightAtom);

  const minSize = 768;
  const maxSize = 1536;

  function handleClick() {
    if (hasUpscaledImage) {
      toast.error(`You cannot upscale the same image twice`, {
        duration: 3000,
        style: toastError,
      });

      return;
    }

    if (uploadedImage || generatedImageUrl) {
      getImage();
    }

    if (!uploadedImage && !generatedImageUrl) {
      toast.error("You must upload or generate an image first", {
        duration: 4000,
        style: toastError,
      });
    }
  }

  // function handleOnEnter(e) {
  //   if (e.key === "Enter") {
  //     e.stopPropagation(); //Should stop double click
  //     handleClick();
  //   }
  // }

  useEffect(() => {
    setUploadedImage("");
    setUploadedImageName("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // This makes sure smaller images are upscaled to fit the size constraints of SD
    if (uploadedImage) {
      if (uploadedImageWidth > maxSize || uploadedImageHeight > maxSize) {
        setScale(maxSize / Math.max(uploadedImageWidth, uploadedImageHeight));
      }

      if (uploadedImageWidth < minSize || uploadedImageHeight < minSize) {
        setScale(minSize / Math.min(uploadedImageWidth, uploadedImageHeight));
      }
    }
  }, [setScale, uploadedImage, uploadedImageHeight, uploadedImageWidth]);

  useEffect(() => {
    const { width, height } = getImageSize(size);

    if (gotGeneratedImage) {
      const img = new Image();

      img.src = generatedImageUrl;

      img.onload = () => {
        setPayloadWidth(img.width);
        setPayloadHeight(img.height);
      };
    } else if (uploadedImage) {
      setPayloadWidth(generatedImageUrl ? width : uploadedImageWidth);
      setPayloadHeight(generatedImageUrl ? height : uploadedImageHeight);
    }
  }, [
    uploadedImage,
    generatedImageUrl,
    gotGeneratedImage,
    scale,
    setPayloadHeight,
    setPayloadWidth,
    size,
    uploadedImageHeight,
    uploadedImageWidth,
  ]);

  if (isLoading) {
    return <Loader label="Loading, this may take a few seconds..." />;
  }

  if (isProcessing) {
    return <></>;
  }

  return (
    <div className="button-row">
      <UploadImage />

      <Button
        tabIndex={0}
        label="Upscale"
        // onKeyUp={(e) => handleOnEnter(e)}
        onClick={handleClick}
        icon={<ImageIcon size={20} />}
        styles="btn-block btn-green"
      />

      {generatedImageUrl && <DownloadButton />}
    </div>
  );
}
