// =================================================================================================== //
//  █████╗ ██████╗ ████████╗███████╗ ██████╗ ██████╗  ██████╗ ███████╗██╗      █████╗ ██████╗ ███████╗ //
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗██╔════╝ ██╔════╝██║     ██╔══██╗██╔══██╗██╔════╝ //
// ███████║██████╔╝   ██║   █████╗  ██║   ██║██████╔╝██║  ███╗█████╗  ██║     ███████║██████╔╝███████╗ //
// ██╔══██║██╔══██╗   ██║   ██╔══╝  ██║   ██║██╔══██╗██║   ██║██╔══╝  ██║     ██╔══██║██╔══██╗╚════██║ //
// ██║  ██║██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║╚██████╔╝███████╗███████╗██║  ██║██████╔╝███████║ //
// ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝ //
// =================================================================================================== //
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { forwardRef, useEffect } from "react";
import placeholder2 from "../../images/PlaceholderImage1.webp";
import placeholder from "../../images/PlaceholderImage3.webp";

import {
  canvasHeightAtom,
  canvasWidthAtom,
  generatedImageUrlAtom,
  imageAtom,
  isProcessingAtom,
  promptAtom,
  tabSelectedAtom,
  uploadedImageAtom,
} from "../../utils/initState";
import Canvas from "../Canvas";

const ImageLoader = forwardRef(function ImageLoader(props, ref) {
  const imageRef = ref;

  const [image, setImage] = useAtom(imageAtom);

  const prompt = useAtomValue(promptAtom);
  const tabSelected = useAtomValue(tabSelectedAtom);
  const isProcessing = useAtomValue(isProcessingAtom);
  const uploadedImage = useAtomValue(uploadedImageAtom);
  const generatedImageUrl = useAtomValue(generatedImageUrlAtom);

  const setCanvasWidth = useSetAtom(canvasWidthAtom);
  const setCanvasHeight = useSetAtom(canvasHeightAtom);

  useEffect(() => {
    if (generatedImageUrl) {
      setImage(generatedImageUrl);
    } else if (!isProcessing && uploadedImage) {
      setImage(uploadedImage);
    } else if (isProcessing) {
      setImage(placeholder2);
    } else {
      setImage(placeholder);
    }
  }, [isProcessing, setImage, generatedImageUrl, uploadedImage]);

  function imageLoader() {
    props.reportWindowSize();

    const width = imageRef.current.clientWidth;
    const height = imageRef.current.clientHeight;

    setCanvasWidth(width);
    setCanvasHeight(height);
  }

  return (
    <>
      <img
        src={image}
        ref={imageRef}
        onLoad={imageLoader}
        className="image"
        alt={prompt}
      />
      {(generatedImageUrl || uploadedImage) && tabSelected === 3 ? (
        <Canvas />
      ) : (
        <></>
      )}
    </>
  );
});

export default ImageLoader;
